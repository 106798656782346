import * as React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import type { OurValuesSection } from '../shared';
import { render } from '@helpers/markdown';

import PngTree from '../images/trees.png';
import PngHouse from '../images/house.png';
import PngCar from '../images/car.png';
import PngBalloon from '../images/balloon.png';
import PngPetrol from '../images/petrol.png';
import PngCoal from '../images/coal.png';

import * as styles from '../our_values.module.scss';

const INFOGRAPHIC_ICONS = [
  ['%TREE', `<img src="${PngTree}" />`],
  ['%HOUSE', `<img src="${PngHouse}" />`],
  ['%CAR', `<img src="${PngCar}" />`],
  ['%BALLOON', `<img src="${PngBalloon}" />`],
  ['%PETROL', `<img src="${PngPetrol}" />`],
  ['%COAL', `<img src="${PngCoal}" />`],
];

const renderWithIcons = (str?: null | string) => {
  str = INFOGRAPHIC_ICONS.reduce(
    (acc, [a, b]) => acc.replace(new RegExp(a, 'g'), b),
    str ?? ''
  );
  str = render(str);

  return str;
};

export const ReducesCarbonEmissions = ({
  body,
  sections,
  title,
}: OurValuesSection) => {
  const [state, setState] = React.useState<{ [key: string]: boolean }>({});

  const onToggle = (key: string) => {
    setState(prev => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Box className={styles.reduces}>
      <Container>
        <Box className={styles.inner}>
          <Box className={styles.content}>
            <h2>{title}</h2>
            <Box
              className={styles.contentBody}
              htmlContent={renderWithIcons(body)}
            />
          </Box>

          <div className={styles.side}>
            <Box className={styles.sections}>
              {sections.map((section, index) => (
                <Box key={index} className={styles.section}>
                  <button
                    className={`${styles.toggleCollapisble} ${
                      state[section.title] ? styles.open : ''
                    }`}
                    onClick={() => onToggle(section.title)}
                  >
                    {section.title}

                    <span />
                  </button>
                  <div className={styles.interlude}>
                    <Box htmlContent={section.body ?? ''} />
                  </div>
                  <div
                    className={`${styles.sectionCollapsible} ${
                      state[section.title] ? styles.open : ''
                    }`}
                  >
                    <div className={styles.sectionCollapsibleInner}>
                      {section.sections.map((subSection, index) => (
                        <Box key={index} className={styles.subSection}>
                          <h3>{subSection.title}</h3>
                          <Box htmlContent={renderWithIcons(subSection.body)} />
                        </Box>
                      ))}
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </Container>
    </Box>
  );
};
