// extracted by mini-css-extract-plugin
export var commitment = "our_values-module--commitment--i1F1E";
export var content = "our_values-module--content--UFNNT";
export var designedForToday = "our_values-module--designedForToday--hJsJm";
export var goodForYou = "our_values-module--goodForYou--dA1LP";
export var image = "our_values-module--image--IqbuA";
export var imageFullHeightOffset = "our_values-module--imageFullHeightOffset--RNiu4";
export var inner = "our_values-module--inner--F+Zs5";
export var open = "our_values-module--open--iGyMq";
export var ourValues = "our_values-module--ourValues--tnbUo";
export var reconciliation = "our_values-module--reconciliation--dUpvG";
export var reduces = "our_values-module--reduces--io2K8";
export var section = "our_values-module--section--yw2u+";
export var sectionCollapsible = "our_values-module--sectionCollapsible--fJAj0";
export var sectionCollapsibleInner = "our_values-module--sectionCollapsibleInner--ajz3i";
export var sections = "our_values-module--sections--eaSF-";
export var subSection = "our_values-module--subSection--UZ9Cj";
export var toggleCollapisble = "our_values-module--toggleCollapisble--lhvVX";